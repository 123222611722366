import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import Vimeo from '@u-wave/react-vimeo';
import React, { createRef, useEffect, useState } from 'react';

import { DiagonalAngleWidth } from '../../constants';
import { Breakpoint, ProjectType, VimeoProps } from '../../types';
import { calcCoverSize } from '../../utils/calcCoverSize';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { BackgroundLine } from '../BackgroundLine';
import { BackgroundLines } from '../BackgroundLines';
import { Contact } from '../Contact/Contact';
import { Button } from '../UI/Button';
import { UnstyledLink } from '../UI/StyledLink';
import { Text } from '../UI/Text';

import { AllProjectsLink } from './AllProjectsLink';
import Arno from './Arno.jpg';
import playLogo from './playLogo.svg';
import { ProjectList } from './ProjectList';

type Props = {
  projects: ProjectType[];
  showreelUrl: string;
};

export function Home({ projects, showreelUrl }: Props) {
  const [coverSize, setCoverSize] = useState<VimeoProps>({
    width: 0,
    height: 0
  });

  const video: any = createRef();
  const container: any = createRef();
  const [videoRef] = useState(video);
  const [containerRef] = useState(container);
  const [videoIsLoading, setVideoIsLoading] = useState(true);

  function ResizeVideo() {
    const ogVideoWidth = videoRef.current.player.element.width;
    const ogVideoHeight = videoRef.current.player.element.height;

    const width = video.current.clientWidth;
    const height = containerRef.current.clientHeight;

    const aspectRatio = ogVideoWidth / ogVideoHeight + 0.1;
    setCoverSize(calcCoverSize(width, height, aspectRatio));
    setVideoIsLoading(false);
  }

  useEffect(() => {
    window.addEventListener('resize', ResizeVideo);
    return () => window.removeEventListener('resize', ResizeVideo);
  }, []);

  return (
    <>
      <SplashContainer>
        <SplashCta>
          <StyledCenterText>
            <h1>
              <Profession mode="italic">Cinematography & Editing</Profession>
            </h1>
            <h2>
              <Goal mode="splashtitle">Custom branded video content.</Goal>
            </h2>
            <UnstyledLink to="/showreel">
              <PlayShowreel mode="primary">
                <PlayLogo src={playLogo} alt="Play" />
                Play showreel
              </PlayShowreel>
            </UnstyledLink>
          </StyledCenterText>
          <ClippingMask>
            <ShowreelPreviewBox ref={containerRef}>
              <StyledVimeo
                ref={videoRef}
                width={coverSize.width}
                height={coverSize.height}
                video={showreelUrl}
                loading={videoIsLoading}
                onPlay={ResizeVideo}
                background
                loop
              />
            </ShowreelPreviewBox>
            <HeroMask url={Arno} />
            <BackgroundLine />
          </ClippingMask>
          <Contact />
        </SplashCta>
      </SplashContainer>
      <StyledProjects>
        <BottomBorder />
        <ProjectList title={'Recent projects'} projects={projects} />
        <BackgroundLine />
        <FooterBorder />
        <AllProjectsLink />
      </StyledProjects>
      <BackgroundLines />
    </>
  );
}

const ClippingMask = styled.div`
  position: absolute;
  clip: rect(0, auto, auto, 0);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;

  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      left: 45vw;
    `
  )};
  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      left: 60vw;
    `
  )};
`;

const StyledProjects = styled.div`
  background-color: white;
  position: relative;
  padding-bottom: 4rem;
  margin-bottom: -2rem;
  z-index: 0;

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      padding-bottom: 0;
    `
  )};
`;

const StyledVimeo = styled(Vimeo)<{ loading: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${props => (props.loading ? 0 : 1)};
  transition: opacity 2s;
`;

const SplashCta = styled.div`
  width: 100%;
  padding-right: ${DiagonalAngleWidth / 2}vh;
  box-sizing: border-box;
  display: inline-block;

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0;
    `
  )};
`;

const ShowreelPreviewBox = styled.div`
  width: ${DiagonalAngleWidth}vh;
  height: 101vh;
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background: black;

  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      width: auto;
      clip-path: none;
    `
  )};
`;

const SplashContainer = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 2rem;
  position: relative;
  z-index: 9;
  overflow: hidden;
  min-height: 95vh;

  ${forBreakpoint(
    Breakpoint.Phone,
    css`
      margin-left: 1rem;
    `
  )};

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      min-height: 90vh;
    `
  )};

  ${forBreakpoint(
    Breakpoint.Desktop,
    css`
      min-height: 100vh;
    `
  )};
`;

const StyledCenterText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: 18rem;

  ${forBreakpoint(
    Breakpoint.TabletPortrait,
    css`
      margin-left: 1.5rem;
    `
  )};

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      margin-right: 30rem;
      max-width: 35rem;
      align-items: flex-end;
    `
  )};

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      max-width: 75rem;
      margin-right: 60rem;
    `
  )};
`;

const Profession = styled(Text)`
  margin-bottom: 1rem;
  display: block;

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      text-align: right;
    `
  )};

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      margin-bottom: 3rem;
    `
  )};
`;

const Goal = styled(Text)`
  margin-bottom: 2rem;
  display: block;

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      text-align: right;
      font-size: 4rem;
      line-height: 4rem;
    `
  )};
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      font-size: 10rem;
      line-height: 10rem;
      margin-bottom: 6rem;
    `
  )};
`;

const PlayShowreel = styled(Button)`
  font-size: 1.1rem;
  background-color: white;
  padding: 0.6rem 1rem;
  border-radius: 50px;

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      margin-right: 2.8rem;
    `
  )};

  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      font-size: 2.5rem;
      border-radius: 4rem;
      height: 7rem;
      padding-right: 4rem;
      margin-right: 7rem;
    `
  )};
`;

const PlayLogo = styled.img`
  margin-right: 0.6rem;
  height: 1rem;
  ${forBreakpoint(
    Breakpoint.UltraWide,
    css`
      min-height: 2.5rem;
      margin-right: 1.5rem;
      padding-left: 2rem;
      @supports (-webkit-appearance: none) {
      }
    `
  )};
`;

const BottomBorder = styled.div`
  width: 100%;
  border-bottom: 1px solid #707070;

  ${forBreakpoint(
    Breakpoint.Wide,
    css`
      border-bottom: 2px solid #707070;
    `
  )};
`;

const HeroMask = styled.div<{ url: string }>`
  background-color: #ffffff;
  background-image: url(${props => props.url});
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
  display: none;

  ${forBreakpoint( 
    Breakpoint.TabletPortrait,
    css`
      display: block;
    `
  )};
`;

const FooterBorder = styled.div`
  border-bottom: 1px solid #707070;
  opacity: 0.25;
  margin-bottom: 1.4rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 3.5rem;

  ${forBreakpoint(
    Breakpoint.TabletLandscape,
    css`
      display: none;
    `
  )}
`;
